.profile-picture {
    height: 150px;
    width: 150px;
    min-width: 150px;
    border-radius: 50%;
    border: 10px solid #C4C4C4;
    margin-left: 1rem;
    margin-right: 0.5rem;
    background-position: center center;
    background-size: cover;
}