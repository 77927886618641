.notes {
    display: grid;
}

.note {
    background-color:#F8F7FF;
    border-radius: 20px;
    color:#6E6D72;
    padding: 30px;
    margin: 10px;
    position: relative;
    text-align: left;
}

.note h1 {
    color:#173A52;
}

.note .date {
    position: absolute;
    top: 20px;
    right: 20px;
    font-style: italic;
    color: #ccc
}

.note .navigation {
    display: flex;
    justify-content: flex-end;
}
.note .button-next-prev {
    color: #000000;
    font-weight: bolder;
    font-size:34px;
    cursor: pointer;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}