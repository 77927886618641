* {
  box-sizing: border-box;
}

body {
  background: #fff;
  padding: 5px;
  height: 100vh;
  margin: 0;
  font: 500 100% "Nunito Sans", "Roboto", "Helvetica Neue", system-ui, sans-serif;
}

.page-wrap {
  background: #fff;
  height: calc(100vh - 10px);
  display: grid;
  grid-template-columns: minmax(10px, 1fr) minmax(10px, 3fr) minmax(10px, 1fr);
  grid-template-rows: min-content 1fr;
  gap: 1px;
}

.dashboard-wrap {
  background: #fff;
  height: calc(100vh - 10px);
  display: grid;
  gap: 1.5rem;
  grid-template-columns: minmax(10px, 1fr) minmax(10px, 3fr);
  grid-template-rows: minmax(10px, 110px) minmax(10px, 3fr);
}

.page-wrap > * {
  padding: 0;
  text-align: center;
}

.page-sidebar-left {
  grid-column: 1/2;
  grid-row: 2/4;
  background: #fff;
}

.page-sidebar-right {
  grid-column: 3/4;
  grid-row: 2/4;
  background: #fff;
}

a {
  text-decoration: none!important;
  color: #0C2942
}

@media (min-width: 576px) {
  .page-wrap > * {
    padding: 1rem;
  }
}

.back-button__mobile {
  display: none;
}


@media (max-width: 600px) {
  .back-button__mobile {
    display: block;
  }

  .page-wrap {
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
  .page-wrap > * {
    grid-column: 1/-1 !important;
    grid-row: auto !important;
  }

  .page-title {
    font-size:1.5rem;
  }

  .dashboard-wrap {
    grid-template-columns: minmax(10px, 1fr) minmax(10px, 3fr);
    grid-template-rows: minmax(80px, 80px) minmax(80px, 80px) 1fr;
    gap: 0.5rem;
  }

  .dashboard-wrap > * {
    grid-column: 1/-1 !important;
    grid-row: auto !important;
  }

  .site-nav {
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 30;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .site-nav button {
    width: 72px;
    height: 72px;
  }

  .site-nav button > .MuiButton-label {
    font-size: 10px!important
  }

  .site-nav a:nth-child(1) {
    display: none;
  }

  .dashboard-wrap .app-logo {
    justify-content: space-evenly;
  }

  .dashboard-wrap .page-main {
    margin-right: 0;
    margin-bottom: 0;
    
  } 


  .main-content-wrapper {
      grid-template-columns: minmax(10px, 1fr);
      display: unset;
  }

  .dashboard-wrap .page-welcome {
    font-size: 1rem;
    text-align: center;
  }

  .dashboard-wrap .page-welcome-subtitle {
    font-size: 1rem;
    text-align: center;
  }

  .main-content-wrapper .img-widget:last-child {
    margin-bottom: 8rem;
    grid-column: 1/-1;
  }
}


@media (min-width: 700px) {
  .calendar-events {
    margin:5px;
  }
}

.formControl {
  margin: 8px!important;
  min-width: 120px!important;
}

.selectEmpty {
  margin-top: 16px!important;
}

.defaultButton {
    margin: 8px!important;
    background-color: #e0e0e0!important;
    color: rgba(0, 0, 0, 0.87)!important;
    font-weight: 600!important;
    font-size: 1rem!important;
    padding: 0.5rem 2rem!important;
    box-shadow: none!important;
}

.defaultButton:hover {
  background-color: #eeeeee!important;
  
}

.dangerButton {
    margin: 8px!important;
    background-color: #e53935!important;
    color: #fff!important;
    font-weight: 600!important;
    font-size: 1rem!important;
    padding: 0.5rem 2rem!important;
}

.dangerButton:hover {
  background-color: #f44336!important;
}

.confirmButton {
    margin: 8px!important;
    background-color: #8bc34a!important;
    color: rgba(0, 0, 0, 0.87)!important;
    font-weight:600!important;
    font-size: 1rem!important;
    padding: 0.5rem 2rem!important;
    box-shadow: none!important;
}

.confirmButton:hover {
  background-color: #a2cf6e!important;
}

.button {
  margin: 16px!important;
}

.table {
  min-width: 700!important;
}

.button-alt {
  margin: 8px!important;
  flex-direction: row!important;
  background-color: #92448B!important;
}

.selected {
  margin: 16px!important;
  background-color: #7A3D79!important;
  color: #fff!important;
  box-shadow: 0 10px 40px 0 rgba(122, 61, 121, 0.5)!important;
}