.MuiButton-containedPrimary, .MuiButton-containedSecondary, .MuiButton-contained {
    line-height: unset;
    padding-left: 25px;
    padding-right:25px;
}

.validation-errors {
    background-color: rgb(253, 236, 234);
    color: rgb(97, 26, 21);
    padding: 1rem;
    list-style-position: inside;
    border-radius: 5px;
}