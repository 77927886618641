.app-logo {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.app-logo__text {
    margin-left: 10px;
    font-size: 2.5rem;
    color:#0C2942!important;
    font-weight: 800;
}

.app-logo__profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.app-logo__username {
    margin-left: 10px;
    display: block;
    font-weight: 400;
}

.app-logo__username span {
    font-weight: 800;
    color: #92448B;
    
}
.back-button__mobile {
   color:#92448B;
   margin-left: -2rem;
   border: 0;
   background: #fff;
}

.patients-wrapper .back-button__mobile {
    display: none;
}