.MuiButton-containedPrimary, .MuiButton-containedSecondary, .MuiButton-contained {
    line-height: unset;
    padding-left: 25px;
    padding-right:25px;
}

.MuiInputLabel-outlined {
    background: #fff;
}
.calendar-add {
    margin-top: 1rem;
}

.calendar-add .MuiButton-label {
    flex-direction: row;
}