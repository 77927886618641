.authorize-backdrop {
    display: flex; 
    height: 100vh; 
    width: 100vw;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0, 0.5);
}