body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rbc-time-header {
  position: sticky;
  top: 35px;
  left: 0;
  z-index: 3;
  background-color: #fff;
}

.action {
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2rem;
  background: rgba(255,255,255, 0.9);
  z-index: 30;
  bottom: 0;
  left: 0;
  width: 100vw;
  -webkit-box-shadow:0px -16px 39px 10px rgba(62,66,66,0.04);
  -moz-box-shadow: 0px -16px 39px 10px rgba(62,66,66,0.04);
  box-shadow: 0px -16px 39px 10px rgba(62,66,66,0.04);
}

.action__add {
  margin-right: 16px;
  margin-left: 0.5rem;

}

.calendar-events {
  margin: 0
}

.rbc-toolbar .rbc-toolbar-label {
  padding: 8px 10px!important;
}
