.page-sidebar {
    grid-row: 1/-1;
    background: #fff;
    margin-left: 1rem;
}

.page-sidebar.left {
    grid-column: 1/2;
}

.page-sidebar.right {
    grid-column: 3/4;
}