details p {
  text-transform: none;
  text-align: left;
}

.MuiButton-label {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 0.8rem!important;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.MuiButton-startIcon {
  margin-left: 0!important;
  margin-right: 0!important;
  
}

.MuiButton-iconSizeLarge > *:first-child {
  font-size:40px!important;
}

.site-nav {
    margin-top: 2rem
}

.page-title {
  color:#0C2942;
  text-align: left;
  margin-left: 2rem;
  font-size: 36px;
} 

.page-welcome {
    color:#0C2942;
    font-size:32px;
    text-align:left;
    margin-bottom: 0;
}
  
.clinic-name {
  color:#92448B;
  white-space: nowrap;
}

.clinic-name span {
  color:#0C2942
}

.page-welcome-subtitle {
    color:#81A0CE;
    font-size:22px;
    text-align:left;
    margin-top: 10px;
    font-weight: 300;
}

.content-wrapper {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap:2rem;
    grid-template-rows:auto!important
}



.patients-main {
  grid-column: 1/4!important;
  background: #F5F9FE;
  border-radius: 20px;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
}

.page-footer {
  grid-column: 1/-1;
  background: #fff;
}

.content-wrapper .MuiButton-label {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 36px!important;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  font-weight: bold;
}

.patients-header {
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0px;
  margin-top:0px;
  margin-bottom: 0.5rem;
}



.patients-header-text {
  margin-left: 0;
  /*display: flex;
  flex-direction: column;
  align-items: flex-start;*/
  text-align: left;
  display: none;
}

.thin {
  font-weight: 100;
}

.patients-wrapper {
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1rem 0.5rem 1rem 0.5rem;
}

.patients-content {
  display: grid;
  grid-gap: 2rem;
  grid-template-rows: auto!important;
  grid-template-columns: repeat(2, 1fr);  
}

.start-screen-header {
  display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-picture--full {
  display:none;
}

.profile-picture--mobile {
  display: block;
  width: 50px; 
  height: 50px; 
  min-width: 50px;
  border: 5px solid #C4C4C4;
}

.clinic-title {
  display: flex;
  width:100%;
  gap: 0.5rem;
  flex-direction: column;
}

.clinic-description {
  display: none;
}

.action button {
  width: 72px;
  height: 72px;
}

.action button > .MuiButton-label {
  font-size: 10px!important;
}

.calendar-events-cal {
  padding: 0;
  border-radius: 0;
}

.rbc-header {
  font-size: 0.8rem!important;
  display:flex!important;
  align-items: center!important;
  justify-content: center!important;
  min-height: 80px!important;
}

@media (max-width: 350px) { 
  .content-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.5rem;
  }
}

@media (max-width: 449.98px) { 
  .content-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }
}

@media (min-width: 450px) { 
  .content-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }
}

@media (min-width: 575.98px) { 
  .content-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }
}

@media (min-width: 576px) { 
  .calendar-events-cal {
    padding: 30px;
    border-radius: 20px;
  }
  
  .rbc-header {
    font-size:1.4rem!important;
  }

  .app-logo__username {
    display: none;
  }
  .app-logo {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    justify-content: flex-start;
  }

  .action button {
    width: 115px;
    height: unset;
  }

  .action button > .MuiButton-label {
    font-size: 0.8rem!important;
  }
  

  .patients-header-text {
    margin-left: 1rem;
    display: block;
  }

  .profile-picture--mobile {
    display: none;
  }

  .clinic-title {
    flex-direction: row;
    gap: 0.5rem;
  }

  .clinic-description {
    display: block;
  }

  .profile-picture--full {
    display:block;
  }

  .patients-main {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .content-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }

  .action {
    display: flex;
    position: initial;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    width: initial;
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
  }


  
  .action__add {
    margin-right: 16px;
    margin-left: 0.5rem;
  
  }
  
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 767.98px) { 
  .action button {
    width: 115px;
  }
  .content-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
 }

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) { 
  

  .content-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }

  .action {
    flex-direction: row!important;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) { 
  .content-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }

  .action__add {
    margin-right: 5rem!important;
  }

 
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1200px) { 
  .content-wrapper {
    grid-template-columns: repeat(5, 1fr);
  }
  
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1500px) { 
  .app-logo {
    width: unset;
  }
  .content-wrapper {
    grid-template-columns: repeat(6, 1fr);
  }

  .patients-wrapper {
    flex-direction: row;
    align-items: center;
  }

  .patients-header {
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-left: 50px;
    margin-top:50px;
    margin-bottom: 50px;
  }

  .app-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
  }

  .profile-picture {
    height: 150px;
    width: 150px;
    min-width: 150px;
    border-radius: 50%;
    border: 10px solid #C4C4C4;
    margin-left: auto;
    margin-right: auto;
    background-position: center center;
    background-size: cover;
  }
}

/* Extra large resolution (desktops, less than 1200px) */
@media (min-width: 1979.98px) { 
  .content-wrapper {
    grid-template-columns: repeat(8, 1fr);
  }
}

/* Extra large resolution (desktops, less than 1200px) */
@media (min-width: 2399.98px) { 
  .content-wrapper {
    grid-template-columns: repeat(12, 1fr);
  }
}
