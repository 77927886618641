.patient-folder {
    width: 100%; 
    background-color: #F3F7FB!important;
    border-radius: 15px!important;
    color: #7A3D79;
    font-size: 1.2rem!important;
    font-weight: 600;
}



.patient-folder:hover {
    background-color: #7A3D79!important;
}

.patient-folder:hover > .MuiButton-label {
    color: #fff;
}