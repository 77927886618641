
  
  details p {
    text-transform: none;
    text-align: left;
  }

  .MuiButton-label {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 0.8rem!important;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .MuiButton-startIcon {
    margin-left: 0!important;
    margin-right: 0!important;
    
  }

  .MuiButton-iconSizeLarge > *:first-child {
    font-size:40px!important;
  }

  .site-nav {
      margin-top: 2rem
  }

  .page-title {
    color:#0C2942;
    text-align: left;
    margin-left: 2rem;
    font-size: 28px;
    margin: 10px;
    margin-top: 1.5rem;
  } 

  .page-welcome {
      color:#0C2942;
      font-size:32px;
      text-align:left;
      margin-bottom: 0;
  }
  
  .patient-name {
      color:#92448B;
      cursor: pointer
  }


.page-welcome-subtitle {
    color:#81A0CE;
    font-size:22px;
    text-align:left;
    margin-top: 10px;
    font-weight: 300;
}

.main-content-wrapper {
    display: grid;
    grid-template-columns: minmax(10px, 1fr) minmax(10px, 1fr);
    /* margin: 1rem; */
    background: #F5F9FE;
    border-radius: 20px;
}
.page-nav {
  grid-column: 2/3!important;
  background: #fff;
  padding-left:0
}

.page-main {
  grid-column: 2/-1!important;
  background: #F5F9FE;
  border-radius:20px;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 600px) {
  .page-main, .page-nav {
    grid-column: 1/-1!important;
  }

  .page-nav {
    display:grid;
    align-items: center;
  }
}


.page-footer {
  grid-column: 1/-1;
  background: #fff;
}


.offer {
  width: 150px;
  height: 175px;
  /*
  box-shadow: 0px 30px 60px rgba(45, 50, 53, 0.35);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #F3F7FB;
  color:#7A3D79;
  margin-right: 20px;
}

.offer:hover {
  background-color: #7A3D79;
  color:#fff;
}

.offer:hover .icon {
  color:#fff;
}

.offer::after {
  content: "";
  position: absolute;
  border-radius: 20px;
  z-index: -1;
  width: 150px;
  height: 175px;
  /*box-shadow: 0 5px 45px rgba(0,0,0,0.2);*/
  box-shadow: 0 10px 40px 0 rgb(122 61 121 / 50%);
  
  
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.offer:hover::after {
  opacity: 1;
}

.offer:hover {
  cursor: pointer;
}

.offer .icon {
  font-size: 48px;
  margin-bottom: 10px;
  color: #7A3D79;
}
 /*
.MuiPaper-root {
  background-color: #2D3235!important;
  color:#D7DDE0!important;
}
.MuiButtonBase-root {
 color:#7A3D79!important;
}*/
.MuiFilledInput-root {
  background:#F3F7FB!important;
}

.offers-widget {
  background-color:#fff;
  box-shadow: 0px 30px 60px rgba(175, 174, 174, 0.35);
  filter: drop-shadow(0px 4px 4px rgba(160, 160, 160, 0.25));
  margin:20px;
  border-radius:20px;
  grid-column: 1/3;
  padding:30px;
  padding-top:10px;
  text-align: left;
  overflow-x: auto;
}

.offers {
  padding-bottom: 30px;
  padding-top:10px;

}

.calendar-main {
  grid-column: 2/4!important;
  background: #F5F9FE;
  border-radius: 20px;
  margin-right: 30px;
  margin-bottom: 30px;
  grid-row: 2/-1 !important;
  overflow: hidden;
}

.calendar-events {
  height: 100%;
  margin:0;
}

.calendar-events-cal {
  height: 100%;
  padding: 30px;
  background: #fff;
  box-shadow: 0px 30px 60px rgb(175 174 174 / 35%);
  filter: drop-shadow(0px 4px 4px rgba(160, 160, 160, 0.25));
  border-radius: 20px;
  align-self: flex-start;
}

.calendar-header {
  display:grid;
  grid-template-columns: minmax(10px, 1fr) minmax(10px, 3fr) minmax(10px, 1fr);
}

.calendar-patient {
  grid-column: 2/3!important;
  background: #fff;
  padding-left:0;
  grid-row: 1;
}

.calendar-patient-info {
  color: #0C2942;
  font-size: 32px;
  text-align: left;
  margin-bottom: 0;
  align-self: flex-end;
}


.rbc-event {
  border-radius: 0px!important;
  padding: 10px;
  border:0 none!important;
 
}

.calendar-primary {
  background-color: #7a3d79!important;
  border: 1px solid #7a3d79!important;
}

.calendar-holidays {
  background-color: rgb(61, 121, 122)!important;
  border: 1px solid rgb(61, 121, 122)!important;
}

.rbc-event-content {
  text-transform: uppercase;
  font-size: x-small;
}

.rbc-timeslot-group {
  min-height: 80px!important;
}

.rbc-header {
  font-size: 1.4rem!important;
  display:flex!important;
  align-items: center!important;
  justify-content: center!important;
  min-height: 80px!important;
}

.rbc-today {
  background-color: #f7fbfd!important;
}

.rbc-allday-cell {
  display: none!important;
}

.rbc-event-label {
	font-size: x-small!important;
  display: none!important;
}

.rbc-time-slot {
	margin: 5px!important;
}

.rbc-event {
  border: 3px solid white!important;
}

.calendar-ordinacija {
  background-color: #6a1b9a!important;
  border-bottom: 0!important;
  color: white !important;
}

.calendar-ordinacija.alt {
  background-color: #9c4dcc!important;
   
  color: white !important;
}

.calendar-ordinacija-2 {
  background-color: #283593!important;
  color: white!important;
}
.calendar-ordinacija-2.alt {
  background-color: #5f5fc4!important;
  color: white!important;
}

.rbc-selected {
  background: #6a1b9a!important;
}

.rbc-btn-group {
  display: flex!important;
  flex-direction: row;
}

.rbc-toolbar {
  position: relative;
  top: 0;
}
.rbc-toolbar button {
  display: flex!important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.rbc-toolbar {
  position: sticky;
  z-index: 10;
  background: #fff;
  top: 0;
}

.rbc-time-header {
  position: sticky;
  top: 100px;
}

@media (min-width: 576px) { 
  .rbc-event-content {
    font-size: small;
  }

  .rbc-time-header {
    position: sticky;
    top: 75px;
  }
}

@media (min-width: 767.98px) { 
  .rbc-event-content {
    font-size: smaller;
  }

  .rbc-time-header {
    position: sticky;
    top: 75px;
  }
}

@media (min-width: 991.98px) { 
  .rbc-event-content {
    font-size: medium;
  }

  .rbc-time-header {
    position: sticky;
    top: 30px;
  }
}

@media (min-width: 1199.98px) { 
  .rbc-event-content {
    font-size: unset;
  }

  .rbc-time-header {
    position: sticky;
    top: 30px;
  }
}