.img-widget {
  background-color: #2d3235;
  box-shadow: 0px 30px 60px rgba(45, 50, 53, 0.35);
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #d7dde0;
  margin: 20px;
  padding: 25px;
  cursor: pointer;
  overflow-x: auto;
}

.img-widget.alt {
  background-color: #555;
}

.img-widget h3 {
  text-align: left;
  font-size: 24px;
  margin: 0;
  margin-bottom: 15px;
  color: #d7dde0;
}

.img-images {
  /* display:grid;
    grid-template: repeat(1, 1fr) / repeat(8, 1fr);
    grid-auto-flow: row dense; */
  display: flex;
  flex-wrap: wrap;
}

.img-images .image {
  position: relative;
  background-repeat: no-repeat;
  /*background-blend-mode: luminosity;*/
  border-radius: 10px;
  background: #000;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  max-width: 100%;
  /* height: auto; */
  vertical-align: middle;
  border-radius: 5px;
  flex: 1 1 auto;
}

.img-images .large-image,
.img-images .small-image {
  width: 100%;
  height: 80px;
  object-fit: cover;
  max-width: 100%;

  vertical-align: middle;
  border-radius: 5px;
}

/* .img-images .small-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
  max-width: 100%;

  vertical-align: middle;
  border-radius: 5px;
} */

.video-title {
  font-size: x-small;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  text-align: center;
}

.video-title-background {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  padding: 3px 10px;
}

.img-images .delete-image {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 10;
  background: rgba(0,0,0,0.3);
  padding: 2px;
  border-radius: 3px;
  display: none;
}

.img-images .image-container {
  position: relative;
  margin: 10px;
  background: #000;
  overflow: hidden;
  max-width: 150px;
  /* height: auto; */
  vertical-align: middle;
  border-radius: 5px;
  flex: 1 1 auto;
}

.img-images .image-container:hover .delete-image {
    display: block;
}
