.page-header {
    grid-column: 1/-1;
    background: #fff;
    padding-top:1.5rem;
    width: 100%;
}

.header-wrapper {
    display: grid;
    grid-template-columns: minmax(10px, 1fr);
    /*grid-template-columns: minmax(10px, 1fr) minmax(10px, 3fr) minmax(10px, 1fr);*/
}

.app-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 2rem;
}